<template>
  <div>
    <!-- src="`/assets/image/about-us/desktop/selling/bg-1.png`" -->
    <div
      class="bg-1"
      :style="`background-image: url('${backgroundImageUrl(1)}');`"
    >
      <div class="content-wrapper px-lg-5 px-7">
        <BlankLayout></BlankLayout>
        <br v-show="isMobile" />
        <br v-show="isMobile" />
        <!-- <router-link :to="{ name: routeName.HOME }">
          <img
            class="Vue_logo"
            alt="Vue logo"
            src="/assets/image/about-us/desktop/selling/logo.png"
          />
        </router-link> -->
        <div class="creator-partner">
          <h1><b>Be A CookX Creator-Partner</b></h1>
          <h1 class="font_Grape"><b>monetise your food business today</b></h1>

          <h1 class="font_Grape"><b>How are we different from others?</b></h1>
        </div>

        <transition-group
          v-intersect="{
            handler: onIntersectDiffSection,
            options: {
              threshold: 1.0
            }
          }"
          name="slide-fade-y"
          class="d-flex justify-content-start align-items-center"
        >
          <template v-for="(i, index) in diffThreeBtn">
            <div
              :key="index"
              v-show="isShowDiff"
              :class="`enter-delay-${index}s`"
              class="text-center gif-contant ml-0 mr-0"
            >
              <img :src="i.img" />
              <div class="gif-contant-t">
                <p class="">
                  <b>{{ i.title }}</b>
                </p>
                <p>
                  {{ i.text }}
                </p>
              </div>
            </div>
          </template>
        </transition-group>
      </div>
    </div>
    <div
      class="bg-2"
      :style="`background-image: url('${backgroundImageUrl(2)}');`"
    >
      <div class="content-wrapper p-5">
        <div style="margin-left: auto; width: 51%">
          <h1 class="font_Grape eco_pack">
            <b>What's Eco Pack?</b>
          </h1>
          <p class="eco_pack p">
            Due to foods storage and best before shelf-life issues result in
            unnecessary food waste. Approximately 17,000 tonnes of food waste is
            generated daily in Malaysia. So we create the idea of “<span
              class="font_Grape"
              >Eco-pack</span
            >”, the pre-measured ingredients pack kit bases on recipe.
          </p>
        </div>

        <div class="d-flex">
          <div style="width: 65%"></div>
          <div class="eco_pack_cintent" style="width: 45%">
            <transition-group
              v-intersect="{
                handler: onIntersectFirstSection,
                options: {
                  threshold: 1.0
                }
              }"
              name="slide-fade-x"
            >
              <template v-for="(i, index) in whatFourBtn">
                <v-row
                  :key="index"
                  v-show="isShowFirst"
                  :class="`enter-delay-${index}s`"
                >
                  <div class="d-flex align-center">
                    <img :src="i.img" />
                    {{ i.text }}
                  </div>
                </v-row>
              </template>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-3"
      :style="`background-image: url('${backgroundImageUrl(3)}');`"
    >
      <div class="submit-form content-wrapper p-5">
        <h1><b>BECOME A COOKX CREATOR-PARTNER TODAY!</b></h1>
        <div>
          <p class="font_Grape mb-5"><b>Sign Up here!</b></p>
          <div class="btn-submit">
            <router-link
              :to="{ name: routeName.SIGNUP }"
              class="ml-sm-2 text-caption text-sm-body-2 submit-btn"
            >
              REGISTER
            </router-link>
          </div>
          <h1 class=""><b>OR</b></h1>
          <div class="d-flex justify-content-center align-items-center">
            <p><b class="font_Grape">Download &nbsp; </b>CookX Asia</p>
          </div>
          <div
            v-intersect="{
              handler: onIntersectDownloadLink,
              options: {
                threshold: 1.0
              }
            }"
            class="no-gutters w-50 pt-3 pb-10 download_img"
          >
            <template v-for="(i, index) in appsIcons">
              <transition :key="index" name="slide-fade-y">
                <!-- <a :href="i.url" target="_blank"> -->
                <img
                  v-show="isShowDownloadLink"
                  :src="i.src"
                  class="col col-3 cursor-pointer"
                  :class="i.class"
                  @click="getAppsDownloadLink(i)"
                />
                <!-- </a> -->
              </transition>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BlankLayout from '@/views/layout/BlankLayout';
  import { DEVICE_TYPE } from '@/core/constants/enums';
  import constantsKey from '@/core/filters/constants-key.filter';
  import {
    LOOKUP_GET_GLOBAL_SETTING,
    LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE
  } from '@/core/store/lookup.module';
  import { formValidationHelper, i18nHelper } from '@/core/utils';
  import { Configuration } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';
  import { uiHelper } from '@/core/utils';

  export default {
    name: 'AboutSelling',
    components: {
      BlankLayout
    },
    data: () => ({
      isShowDownloadLink: false,
      isShowFirst: false,
      isShowDiff: false,
      routeName: ROUTE_NAME,
      assetEnvironment: Configuration.value('assetEnvironment'),
      spacesUrl: Configuration.value('spacesUrl'),
      formValidation: formValidationHelper,
      email: null,
      appsIcons: [
        {
          src: '/assets/image/google-play-store.png',
          key: DEVICE_TYPE.ANDROID,
          class: ' enter-delay-1000ms',
          url: 'https://play.google.com/store/apps/details?id=com.cook.xpert.cookx'
        },
        {
          src: '/assets/image/apple-app-store.png',
          key: DEVICE_TYPE.IOS,
          class: ' enter-delay-2000ms',
          url: 'https://apps.apple.com/us/app/cookx-asia/id1560770482'
        },
        {
          src: '/assets/image/huawei-app-gallery.png',
          key: DEVICE_TYPE.HUAWEI,
          class: ' enter-delay-3s',
          url: 'https://appgallery.huawei.com/app/C104855413'
        }
      ],
      diffThreeBtn: [
        {
          title: 'Video Sharing',
          text: 'Create you own step-by-step video or photo tutorial content',
          img: '/assets/image/about-selling/video-sharing2.png'
        },
        {
          title: 'Eco Pack',
          text: 'Develop own meal or baking kit with pre-measured ingredients',
          img: '/assets/image/about-selling/Eco-Pack.png'
        },
        {
          title: 'Pre-Order',
          text: 'Customisable pre-order setting from availability to delivery ',
          img: '/assets/image/about-selling/preorder.png'
        }
      ],
      whatFourBtn: [
        {
          text: 'Shoppable Recipes',
          img: '/assets/image/about-selling/Shoppable-Recipesai.png'
        },
        {
          text: 'Pre-measured Ingredients',
          img: '/assets/image/about-selling/Pre-measured-Ingredients.png'
        },
        {
          text: 'Zero Wastage',
          img: '/assets/image/about-selling/Zero-wastage.png'
        },
        {
          text: 'Easy & Convenience',
          img: '/assets/image/about-selling/Easy-&-Convenience.png'
        }
      ]
    }),
    computed: {
      globalSettingComplete() {
        return this.$store.state.lookup.globalSetting.complete;
      },
      currentScreenWidth() {
        return window.screen.width;
      },
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      logoMaxWidth() {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 160;
        } else if (this.$vuetify.breakpoint.smOnly) {
          return 120;
        } else {
          return 90;
        }
      },
      aboutUsSrc() {
        let device = this.isMobile ? 'mobile' : 'desktop';

        return `/assets/image/about-us/${device}/silling/logo.png`;
      },
      chillNCookCommunity() {
        return [
          {
            src: `${this.spacesUrl}/${this.assetEnvironment}/assets/about-us_cook-smarti.gf`
          },
          {
            src: '/assets/image/about-us_cook-eco.png'
          }
        ];
      },
      gettyEatCommunity() {
        return [
          {
            src: '/assets/image/about-us_get-smart.png'
          },
          {
            src: `${this.spacesUrl}/${this.assetEnvironment}/assets/about-us_get-to-eat.gif`
          }
        ];
      },
      bg1MinHeight() {
        const DEFAULT_DESKTOP_MIN_HEIGHT = 1508;
        const DEFAULT_MOBILE_MIN_HEIGHT = 696;

        return this.getBackgroundImageMinHeight(
          DEFAULT_DESKTOP_MIN_HEIGHT,
          DEFAULT_MOBILE_MIN_HEIGHT
        );
      },
      bg2MinHeight() {
        const DEFAULT_DESKTOP_MIN_HEIGHT = 1006;
        const DEFAULT_MOBILE_MIN_HEIGHT = 481;

        return this.getBackgroundImageMinHeight(
          DEFAULT_DESKTOP_MIN_HEIGHT,
          DEFAULT_MOBILE_MIN_HEIGHT
        );
      },
      bg3MinHeight() {
        const DEFAULT_DESKTOP_MIN_HEIGHT = 1047;
        const DEFAULT_MOBILE_MIN_HEIGHT = 533;

        return this.getBackgroundImageMinHeight(
          DEFAULT_DESKTOP_MIN_HEIGHT,
          DEFAULT_MOBILE_MIN_HEIGHT
        );
      }
    },
    watch: {
      globalSettingComplete() {
        let response = this.$store.state.lookup.globalSetting;
        if (response.complete) {
          this.globalSettingCompleteAction(response);
        }
      }
    },
    methods: {
      globalSettingCompleteAction(response) {
        if (response.code == 0) {
          uiHelper.openPage(response.data.url, '_self');
        } else {
          console.log(response);
        }

        this.initialGetGlobalSettingState();
      },
      getAppsDownloadLink(value) {
        let platformKey = constantsKey('deviceType', value.key);
        let data = {
          platform: platformKey
        };

        this.getGlobalSetting(data);
      },
      getGlobalSetting(data) {
        this.$store.dispatch(LOOKUP_GET_GLOBAL_SETTING, { data });
      },
      onIntersectFirstSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFirst = true;
      },
      onIntersectDiffSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowDiff = true;
      },
      onIntersectDownloadLink(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowDownloadLink = true;
      },
      backgroundImageUrl(value) {
        return `/assets/image/about-us/desktop/selling/bg-${value}.jpg`;
      },
      getBackgroundImageMinHeight(desktopMinHeight, mobileMinHeight) {
        const BACKGROUND_IMAGE_DESKTOP_WIDTH = 1508;
        const BACKGROUND_IMAGE_MOBILE_WIDTH = 428;
        const HEIGHT_ADJUSTMENT = 108;

        let isMobile = this.isMobile;
        let height = isMobile ? mobileMinHeight : desktopMinHeight;
        let currentScreenWidth = this.$vuetify.breakpoint.width;

        if (
          currentScreenWidth >
          (isMobile
            ? BACKGROUND_IMAGE_MOBILE_WIDTH
            : BACKGROUND_IMAGE_DESKTOP_WIDTH)
        ) {
          height =
            (isMobile
              ? mobileMinHeight + HEIGHT_ADJUSTMENT
              : desktopMinHeight) +
            (currentScreenWidth -
              (isMobile
                ? BACKGROUND_IMAGE_MOBILE_WIDTH
                : BACKGROUND_IMAGE_DESKTOP_WIDTH));
        }

        return height;
      },
      submitEmailSubscriptionCompleteAction(response) {
        let type = response.code == 0 ? 'success' : 'error';
        let title = i18nHelper.getMessage('label.emailSubscription');
        let description = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.resetForm();
              }
            }
          ];
        } else {
          buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
        }

        this.openAppDialogInfo(type, title, description, buttons);
        this.initialSubmitEmailSubscription();
      },
      initialGetGlobalSettingState() {
        this.$store.dispatch(LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE);
      }
    }
  };
</script>
<style lang="scss">
  // This Page Only ::start
  @media (max-width: 500px) {
    nav .navigation-bar {
      padding: 0 7%;
    }
  }
  // This Page Only ::end

  .Vue_logo {
    padding-top: 6vw;
    width: 14vw;
  }

  .font_Grape {
    font-family: 'Caveat';
  }

  .bg-1 {
    background-repeat: no-repeat;
    background-size: 100%;
    color: #334856;
  }

  .bg-2 {
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .bg-3 {
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    background-position: center top;
  }

  .creator-partner {
    padding: 12vw 0px 7vw;
  }

  .creator-partner h1 {
    font-size: 2.7vw;
  }

  .creator-partner h1:last-child {
    padding-top: 5vw;
  }

  .gif-contant {
    width: 25%;
    margin: 0 15px;
  }

  .gif-contant-t p:first-child {
    font-size: 1.5vw;
  }

  .gif-contant-t p:nth-child(2) {
    font-size: 1.1vw;
  }

  .gif-contant div {
    width: unset;
    padding: 0px 10px;
  }

  .gif-contant img {
    width: 8vw;
  }

  .eco_pack {
    font-size: 5vw;
    color: #fff;
  }

  .eco_pack.p {
    font-size: 1.4vw;
    color: #fff;
    width: 40vw;
    text-align: left;
    padding-bottom: 2vw;
  }

  .eco_pack.p span {
    font-size: 1.6vw;
  }

  .eco_pack_cintent img {
    width: 11vw;
    padding-right: 3vw;
  }

  .eco_pack_cintent div {
    font-size: 27px;
    color: #fff;
    font-size: 1.4vw;
    padding-top: 1.5vw;
  }

  .submit-form p {
    font-size: 2.8vw;
  }

  .submit-form h1 {
    font-size: 3vw;
  }

  .submit-form h1:nth-child(1),
  .submit-form div h1:nth-of-type(1) {
    padding: 5vw 0 5vw 0;
  }

  .btn-submit .submit-btn {
    background: var(--v-primary-base);
    color: var(--v-secondary-base) !important;
    border-radius: 50px;
    padding: 1.2vw 4vw !important;
    font-size: 1vw !important;
    min-height: 3.7vw;
    font-weight: bold;
    text-decoration: none;
  }

  .download_img {
    width: 80%;
  }
  p {
    margin-top: 0;
    margin-bottom: 0 !important;
  }
  @media screen and (min-width: 920px) {
    .gif-contant-t {
      padding: 1vw 0;
    }

    .gif-contant-t p:nth-of-type(1) {
      padding: 1.5vw 0;
    }

    .gif-contant-t p:nth-of-type(2) {
      padding-bottom: 4vw;
    }

    .eco_pack_cintent {
      padding-bottom: 4vw;
    }
  }

  @media screen and (max-width: 920px) {
    .eco_pack_cintent div {
      padding: 1vw 0;
    }

    .submit-form h1 {
      padding: 3vw 0 5vw 0;
    }

    .submit-form p {
      font-size: 5vw;
    }

    .btn-submit input {
      font-size: 1.5vw;
      width: 25vw;
      height: 5.5vw;
    }

    .btn-submit div {
      height: 5.5vw !important;
      min-width: unset !important;
      font-size: 2.5vw !important;
    }

    .btn-submit .submit-btn {
      min-height: 4.2vw;
      min-width: 3vw;
      font-size: 2vw !important;
    }

    .download_img {
      width: 100%;
    }
  }
</style>
