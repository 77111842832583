var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-1",style:(`background-image: url('${_vm.backgroundImageUrl(1)}');`)},[_c('div',{staticClass:"content-wrapper px-lg-5 px-7"},[_c('BlankLayout'),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),_vm._m(0),_c('transition-group',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersectDiffSection,
          options: {
            threshold: 1.0
          }
        }),expression:"{\n          handler: onIntersectDiffSection,\n          options: {\n            threshold: 1.0\n          }\n        }"}],staticClass:"d-flex justify-content-start align-items-center",attrs:{"name":"slide-fade-y"}},[_vm._l((_vm.diffThreeBtn),function(i,index){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDiff),expression:"isShowDiff"}],key:index,staticClass:"text-center gif-contant ml-0 mr-0",class:`enter-delay-${index}s`},[_c('img',{attrs:{"src":i.img}}),_c('div',{staticClass:"gif-contant-t"},[_c('p',{},[_c('b',[_vm._v(_vm._s(i.title))])]),_c('p',[_vm._v(" "+_vm._s(i.text)+" ")])])])]})],2)],1)]),_c('div',{staticClass:"bg-2",style:(`background-image: url('${_vm.backgroundImageUrl(2)}');`)},[_c('div',{staticClass:"content-wrapper p-5"},[_vm._m(1),_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"65%"}}),_c('div',{staticClass:"eco_pack_cintent",staticStyle:{"width":"45%"}},[_c('transition-group',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersectFirstSection,
              options: {
                threshold: 1.0
              }
            }),expression:"{\n              handler: onIntersectFirstSection,\n              options: {\n                threshold: 1.0\n              }\n            }"}],attrs:{"name":"slide-fade-x"}},[_vm._l((_vm.whatFourBtn),function(i,index){return [_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowFirst),expression:"isShowFirst"}],key:index,class:`enter-delay-${index}s`},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{attrs:{"src":i.img}}),_vm._v(" "+_vm._s(i.text)+" ")])])]})],2)],1)])])]),_c('div',{staticClass:"bg-3",style:(`background-image: url('${_vm.backgroundImageUrl(3)}');`)},[_c('div',{staticClass:"submit-form content-wrapper p-5"},[_vm._m(2),_c('div',[_vm._m(3),_c('div',{staticClass:"btn-submit"},[_c('router-link',{staticClass:"ml-sm-2 text-caption text-sm-body-2 submit-btn",attrs:{"to":{ name: _vm.routeName.SIGNUP }}},[_vm._v(" REGISTER ")])],1),_vm._m(4),_vm._m(5),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersectDownloadLink,
            options: {
              threshold: 1.0
            }
          }),expression:"{\n            handler: onIntersectDownloadLink,\n            options: {\n              threshold: 1.0\n            }\n          }"}],staticClass:"no-gutters w-50 pt-3 pb-10 download_img"},[_vm._l((_vm.appsIcons),function(i,index){return [_c('transition',{key:index,attrs:{"name":"slide-fade-y"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDownloadLink),expression:"isShowDownloadLink"}],staticClass:"col col-3 cursor-pointer",class:i.class,attrs:{"src":i.src},on:{"click":function($event){return _vm.getAppsDownloadLink(i)}}})])]})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"creator-partner"},[_c('h1',[_c('b',[_vm._v("Be A CookX Creator-Partner")])]),_c('h1',{staticClass:"font_Grape"},[_c('b',[_vm._v("monetise your food business today")])]),_c('h1',{staticClass:"font_Grape"},[_c('b',[_vm._v("How are we different from others?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"auto","width":"51%"}},[_c('h1',{staticClass:"font_Grape eco_pack"},[_c('b',[_vm._v("What's Eco Pack?")])]),_c('p',{staticClass:"eco_pack p"},[_vm._v(" Due to foods storage and best before shelf-life issues result in unnecessary food waste. Approximately 17,000 tonnes of food waste is generated daily in Malaysia. So we create the idea of “"),_c('span',{staticClass:"font_Grape"},[_vm._v("Eco-pack")]),_vm._v("”, the pre-measured ingredients pack kit bases on recipe. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('b',[_vm._v("BECOME A COOKX CREATOR-PARTNER TODAY!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font_Grape mb-5"},[_c('b',[_vm._v("Sign Up here!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{},[_c('b',[_vm._v("OR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('p',[_c('b',{staticClass:"font_Grape"},[_vm._v("Download   ")]),_vm._v("CookX Asia")])])
}]

export { render, staticRenderFns }